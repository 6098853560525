top-bar {
  display: flex;
  justify-items: center;
  width: 100%;
  height: var(--top-bar-height);
  color: var(--color--component--text-main);
  background: var(--color--component--background-main);

  position: fixed;
  z-index: 100;
}

top-bar .wide-screen-menu {
  display: inline-flex;
}

top-bar .narrow-screen-menu {
  display: none;
}

top-bar sl-dropdown.narrow-screen-menu {
  padding: 5px 5px 5px var(--distance-s);
}

top-bar .narrow-screen-menu sl-icon {
  vertical-align: text-bottom;
}

@media screen and (max-width: 430px) {
  top-bar .wide-screen-menu {
    display: none;
  }

  top-bar .narrow-screen-menu {
    display: inline-flex;
  }
}

top-bar nav {
  display: inline-flex;
  align-items: center;
  height: var(--top-bar-height);
}

top-bar a {
  text-decoration: none;
}

top-bar nav>a {
  padding: var(--distance);
  border-radius: 2rem;
  color: var(--color--component--text-main-link);
  transition: color ease-in-out 1s;
}

@media (hover: hover) and (pointer: fine) {
  top-bar .hover:hover {
    background-color: var(--color--component--background-secondary);
  }
}

/* purgecss start ignore */
.load-active {
  background-color: var(--color--component--background-secondary);
}

top-bar .active {
  text-decoration: underline;
}

/* purgecss end ignore */

top-bar .user-status {
  align-self: center;
  align-items: center;
  margin-left: auto;
  padding-right: var(--distance);
}