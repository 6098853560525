:root {
  --top-bar-height: 50px;
  --distance: 8px;
  --distance-s: 8px;
  --distance-m: 16px;
  --distance-l: 24px;
  --padding-icons: 2px;
  --color--text-main: #383838;
  --color--text-link: black;
  --color--text-heay: #383838;
  --color--background-main: white;
  --color--background-secondary: lightgray;
  --color--background-tertiary: gray;
  --color--component--text-main: #e8e8e8;
  --color--component--background-main: #8d10ad;
  --color--component--text-secondary: #242020;
  --color--component--background-secondary: #eb26b0;
  --color--component--text-main-link: #e8e8e8;
  --color--component-main: lightgray;
  --color--component--background: white;
  --color--spinner: #6126eba0;
  --color--signal--ok: limegreen;
  --color--signal--warning: yellow;
  --color--signal--working: blueviolet;
  --color--signal--error: red;
  --box-shadow-s: 0 8px 16px 0 #1f26875e;
  --border: .1rem solid var(--color--component--background-main);
  --border-radius: .4rem;
  --sl-color-primary-50: #fbf8fe;
  --sl-color-primary-100: #f3e8fb;
  --sl-color-primary-200: #ead7f9;
  --sl-color-primary-300: #e0c4f6;
  --sl-color-primary-400: #d3aaf1;
  --sl-color-primary-500: #c188ec;
  --sl-color-primary-600: #ad61e6;
  --sl-color-primary-700: #9444d0;
  --sl-color-primary-800: #7a38ac;
  --sl-color-primary-900: #57287b;
  --sl-color-primary-950: #36194c;
}

li {
  list-style-type: none;
}

#wrapper {
  top: var(--top-bar-height);
  height: calc(100dvh - var(--top-bar-height));
  grid-template-columns: 1fr;
  grid-template-areas: "content";
  width: 100vw;
  display: grid;
  position: absolute;
  overflow: hidden;
}

#sidebarleft {
  grid-area: sidebar-left;
}

#sidebarright {
  grid-area: sidebar-right;
}

#content {
  height: calc(100dvh - var(--top-bar-height));
  grid-area: content;
}

.signal-ok {
  color: var(--color--signal--ok);
}

.signal-working {
  color: var(--color--signal--working);
}

.signal-warning {
  color: var(--color--signal--warning);
}

.signal-error {
  color: var(--color--signal--error);
}

.pulse:hover {
  animation: .5s linear pulse;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

.pulse-small:hover {
  animation: .35s linear pulsesmall;
}

@keyframes pulsesmall {
  0% {
    transform: scale(1);
  }

  50% {
    transform: translateX(2rem)scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.zoom:hover {
  transition: all .5s;
  transform: scale(1.1);
}

@media only screen and (width <= 600px) {
  :root {
    --thumb-columns: 3;
    --card-columns: 1fr;
  }

  h2 {
    font-size: x-large;
  }

  h3 {
    font-size: large;
  }

  #wrapper {
    grid-template-columns: 1px minmax(calc(100vw - 2px), 1fr) 1px;
    grid-template-areas: "sidebar-left content sidebar-right";
  }
}

@media only screen and (width >= 601px) {
  :root {
    --thumb-columns: 4;
    --card-columns: 1fr 1fr;
  }

  #wrapper {
    grid-template-columns: 1px minmax(calc(100vw - 2px), 1fr) 1px;
    grid-template-areas: "sidebar-left content sidebar-right";
  }
}

@media only screen and (width >= 1024px) {
  :root {
    --thumb-columns: 7;
    --card-columns: 1fr 1fr 1fr;
  }

  h2 {
    font-size: x-large;
  }

  h3 {
    font-size: large;
  }

  #wrapper {
    grid-template-columns: 1fr minmax(1024px, 1fr) 1fr;
    grid-template-areas: "sidebar-left content sidebar-right";
  }
}

html {
  overflow: hidden;
}

body {
  background-color: var(--color--background-main);
  margin: 0;
  font-family: Inter, sans-serif;
}

a, .link {
  color: var(--color--text-link);
  cursor: pointer;
}

.padding {
  padding: var(--distance);
}

.padding-s {
  padding: var(--distance-s);
}

.padding-m {
  padding: var(--distance-m);
}

.padding-l {
  padding: var(--distance-l);
}

.pull-right {
  float: right;
}

.blur {
  filter: blur(2rem);
}

dialog {
  padding: var(--distance-l);
  width: 80vw;
  max-width: 800px;
}

dialog .button-row {
  padding-top: calc(2 * var(--distance-l));
  justify-content: space-between;
  display: flex;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  border: 3px solid purple;
  width: 100%;
}

th, td {
  text-align: left;
  border: 1px solid purple;
  padding: 1%;
}

td.elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
}

sl-icon, sl-icon-button {
  font-size: 1.4rem;
  transition: all .2s ease-in-out;
}

:root, :host, .sl-theme-light {
  color-scheme: light;
  --sl-color-gray-50: #f9f9f9;
  --sl-color-gray-100: #f4f4f5;
  --sl-color-gray-200: #e4e4e7;
  --sl-color-gray-300: #d4d4d8;
  --sl-color-gray-400: #a1a1aa;
  --sl-color-gray-500: #71717a;
  --sl-color-gray-600: #52525b;
  --sl-color-gray-700: #3f3f46;
  --sl-color-gray-800: #27272a;
  --sl-color-gray-900: #18181b;
  --sl-color-gray-950: #131316;
  --sl-color-red-50: #fef2f2;
  --sl-color-red-100: #fee2e2;
  --sl-color-red-200: #fecaca;
  --sl-color-red-300: #fca5a5;
  --sl-color-red-400: #f87171;
  --sl-color-red-500: #ef4444;
  --sl-color-red-600: #dc2626;
  --sl-color-red-700: #b91c1c;
  --sl-color-red-800: #991b1b;
  --sl-color-red-900: #7f1d1d;
  --sl-color-red-950: #501414;
  --sl-color-orange-50: #fff7ed;
  --sl-color-orange-100: #ffedd5;
  --sl-color-orange-200: #fed7aa;
  --sl-color-orange-300: #fdba74;
  --sl-color-orange-400: #fb923c;
  --sl-color-orange-500: #f97316;
  --sl-color-orange-600: #ea580c;
  --sl-color-orange-700: #c2410c;
  --sl-color-orange-800: #9a3412;
  --sl-color-orange-900: #7c2d12;
  --sl-color-orange-950: #52200f;
  --sl-color-amber-50: #fffbeb;
  --sl-color-amber-100: #fef3c7;
  --sl-color-amber-200: #fde68a;
  --sl-color-amber-300: #fcd34d;
  --sl-color-amber-400: #fbbf24;
  --sl-color-amber-500: #f59e0b;
  --sl-color-amber-600: #d97706;
  --sl-color-amber-700: #b45309;
  --sl-color-amber-800: #92400e;
  --sl-color-amber-900: #78350f;
  --sl-color-amber-950: #4a230b;
  --sl-color-yellow-50: #fefce8;
  --sl-color-yellow-100: #fef9c3;
  --sl-color-yellow-200: #fef08a;
  --sl-color-yellow-300: #fde047;
  --sl-color-yellow-400: #facc15;
  --sl-color-yellow-500: #eab308;
  --sl-color-yellow-600: #ca8a04;
  --sl-color-yellow-700: #a16207;
  --sl-color-yellow-800: #854d0e;
  --sl-color-yellow-900: #713f12;
  --sl-color-yellow-950: #3c260b;
  --sl-color-lime-50: #f7fee7;
  --sl-color-lime-100: #ecfccb;
  --sl-color-lime-200: #d9f99d;
  --sl-color-lime-300: #bef264;
  --sl-color-lime-400: #a3e635;
  --sl-color-lime-500: #84cc16;
  --sl-color-lime-600: #65a30d;
  --sl-color-lime-700: #4d7c0f;
  --sl-color-lime-800: #3f6212;
  --sl-color-lime-900: #365314;
  --sl-color-lime-950: #26390e;
  --sl-color-green-50: #f0fdf4;
  --sl-color-green-100: #dcfce7;
  --sl-color-green-200: #bbf7d0;
  --sl-color-green-300: #86efac;
  --sl-color-green-400: #4ade80;
  --sl-color-green-500: #22c55e;
  --sl-color-green-600: #16a34a;
  --sl-color-green-700: #15803d;
  --sl-color-green-800: #166534;
  --sl-color-green-900: #14532d;
  --sl-color-green-950: #0c311b;
  --sl-color-emerald-50: #ecfdf5;
  --sl-color-emerald-100: #d1fae5;
  --sl-color-emerald-200: #a7f3d0;
  --sl-color-emerald-300: #6ee7b7;
  --sl-color-emerald-400: #34d399;
  --sl-color-emerald-500: #10b981;
  --sl-color-emerald-600: #059669;
  --sl-color-emerald-700: #047857;
  --sl-color-emerald-800: #065f46;
  --sl-color-emerald-900: #064e3b;
  --sl-color-emerald-950: #032d22;
  --sl-color-teal-50: #f0fdfa;
  --sl-color-teal-100: #ccfbf1;
  --sl-color-teal-200: #99f6e4;
  --sl-color-teal-300: #5eead4;
  --sl-color-teal-400: #2dd4bf;
  --sl-color-teal-500: #14b8a6;
  --sl-color-teal-600: #0d9488;
  --sl-color-teal-700: #0f766e;
  --sl-color-teal-800: #115e59;
  --sl-color-teal-900: #134e4a;
  --sl-color-teal-950: #0c2e2c;
  --sl-color-cyan-50: #ecfeff;
  --sl-color-cyan-100: #cffafe;
  --sl-color-cyan-200: #a5f3fc;
  --sl-color-cyan-300: #67e8f9;
  --sl-color-cyan-400: #22d3ee;
  --sl-color-cyan-500: #06b6d4;
  --sl-color-cyan-600: #0891b2;
  --sl-color-cyan-700: #0e7490;
  --sl-color-cyan-800: #155e75;
  --sl-color-cyan-900: #164e63;
  --sl-color-cyan-950: #103442;
  --sl-color-sky-50: #f0f9ff;
  --sl-color-sky-100: #e0f2fe;
  --sl-color-sky-200: #bae6fd;
  --sl-color-sky-300: #7dd3fc;
  --sl-color-sky-400: #38bdf8;
  --sl-color-sky-500: #0ea5e9;
  --sl-color-sky-600: #0284c7;
  --sl-color-sky-700: #0369a1;
  --sl-color-sky-800: #075985;
  --sl-color-sky-900: #0c4a6e;
  --sl-color-sky-950: #0b3249;
  --sl-color-blue-50: #eff6ff;
  --sl-color-blue-100: #dbeafe;
  --sl-color-blue-200: #bfdbfe;
  --sl-color-blue-300: #93c5fd;
  --sl-color-blue-400: #60a5fa;
  --sl-color-blue-500: #3b82f6;
  --sl-color-blue-600: #2563eb;
  --sl-color-blue-700: #1d4ed8;
  --sl-color-blue-800: #1e40af;
  --sl-color-blue-900: #1e3a8a;
  --sl-color-blue-950: #152149;
  --sl-color-indigo-50: #eef2ff;
  --sl-color-indigo-100: #e0e7ff;
  --sl-color-indigo-200: #c7d2fe;
  --sl-color-indigo-300: #a5b4fc;
  --sl-color-indigo-400: #818cf8;
  --sl-color-indigo-500: #6366f1;
  --sl-color-indigo-600: #4f46e5;
  --sl-color-indigo-700: #4338ca;
  --sl-color-indigo-800: #3730a3;
  --sl-color-indigo-900: #312e81;
  --sl-color-indigo-950: #242154;
  --sl-color-violet-50: #f5f3ff;
  --sl-color-violet-100: #ede9fe;
  --sl-color-violet-200: #ddd6fe;
  --sl-color-violet-300: #c4b5fd;
  --sl-color-violet-400: #a78bfa;
  --sl-color-violet-500: #8b5cf6;
  --sl-color-violet-600: #7c3aed;
  --sl-color-violet-700: #6d28d9;
  --sl-color-violet-800: #5b21b6;
  --sl-color-violet-900: #4c1d95;
  --sl-color-violet-950: #311558;
  --sl-color-purple-50: #faf5ff;
  --sl-color-purple-100: #f3e8ff;
  --sl-color-purple-200: #e9d5ff;
  --sl-color-purple-300: #d8b4fe;
  --sl-color-purple-400: #c084fc;
  --sl-color-purple-500: #a855f7;
  --sl-color-purple-600: #9333ea;
  --sl-color-purple-700: #7e22ce;
  --sl-color-purple-800: #6b21a8;
  --sl-color-purple-900: #581c87;
  --sl-color-purple-950: #2f1143;
  --sl-color-fuchsia-50: #fdf4ff;
  --sl-color-fuchsia-100: #fae8ff;
  --sl-color-fuchsia-200: #f5d0fe;
  --sl-color-fuchsia-300: #f0abfc;
  --sl-color-fuchsia-400: #e879f9;
  --sl-color-fuchsia-500: #d946ef;
  --sl-color-fuchsia-600: #c026d3;
  --sl-color-fuchsia-700: #a21caf;
  --sl-color-fuchsia-800: #86198f;
  --sl-color-fuchsia-900: #701a75;
  --sl-color-fuchsia-950: #38103a;
  --sl-color-pink-50: #fdf2f8;
  --sl-color-pink-100: #fce7f3;
  --sl-color-pink-200: #fbcfe8;
  --sl-color-pink-300: #f9a8d4;
  --sl-color-pink-400: #f472b6;
  --sl-color-pink-500: #ec4899;
  --sl-color-pink-600: #db2777;
  --sl-color-pink-700: #be185d;
  --sl-color-pink-800: #9d174d;
  --sl-color-pink-900: #831843;
  --sl-color-pink-950: #430e23;
  --sl-color-rose-50: #fff1f2;
  --sl-color-rose-100: #ffe4e6;
  --sl-color-rose-200: #fecdd3;
  --sl-color-rose-300: #fda4af;
  --sl-color-rose-400: #fb7185;
  --sl-color-rose-500: #f43f5e;
  --sl-color-rose-600: #e11d48;
  --sl-color-rose-700: #be123c;
  --sl-color-rose-800: #9f1239;
  --sl-color-rose-900: #881337;
  --sl-color-rose-950: #4a0d20;
  --sl-color-primary-50: var(--sl-color-sky-50);
  --sl-color-primary-100: var(--sl-color-sky-100);
  --sl-color-primary-200: var(--sl-color-sky-200);
  --sl-color-primary-300: var(--sl-color-sky-300);
  --sl-color-primary-400: var(--sl-color-sky-400);
  --sl-color-primary-500: var(--sl-color-sky-500);
  --sl-color-primary-600: var(--sl-color-sky-600);
  --sl-color-primary-700: var(--sl-color-sky-700);
  --sl-color-primary-800: var(--sl-color-sky-800);
  --sl-color-primary-900: var(--sl-color-sky-900);
  --sl-color-primary-950: var(--sl-color-sky-950);
  --sl-color-success-50: var(--sl-color-green-50);
  --sl-color-success-100: var(--sl-color-green-100);
  --sl-color-success-200: var(--sl-color-green-200);
  --sl-color-success-300: var(--sl-color-green-300);
  --sl-color-success-400: var(--sl-color-green-400);
  --sl-color-success-500: var(--sl-color-green-500);
  --sl-color-success-600: var(--sl-color-green-600);
  --sl-color-success-700: var(--sl-color-green-700);
  --sl-color-success-800: var(--sl-color-green-800);
  --sl-color-success-900: var(--sl-color-green-900);
  --sl-color-success-950: var(--sl-color-green-950);
  --sl-color-warning-50: var(--sl-color-amber-50);
  --sl-color-warning-100: var(--sl-color-amber-100);
  --sl-color-warning-200: var(--sl-color-amber-200);
  --sl-color-warning-300: var(--sl-color-amber-300);
  --sl-color-warning-400: var(--sl-color-amber-400);
  --sl-color-warning-500: var(--sl-color-amber-500);
  --sl-color-warning-600: var(--sl-color-amber-600);
  --sl-color-warning-700: var(--sl-color-amber-700);
  --sl-color-warning-800: var(--sl-color-amber-800);
  --sl-color-warning-900: var(--sl-color-amber-900);
  --sl-color-warning-950: var(--sl-color-amber-950);
  --sl-color-danger-50: var(--sl-color-red-50);
  --sl-color-danger-100: var(--sl-color-red-100);
  --sl-color-danger-200: var(--sl-color-red-200);
  --sl-color-danger-300: var(--sl-color-red-300);
  --sl-color-danger-400: var(--sl-color-red-400);
  --sl-color-danger-500: var(--sl-color-red-500);
  --sl-color-danger-600: var(--sl-color-red-600);
  --sl-color-danger-700: var(--sl-color-red-700);
  --sl-color-danger-800: var(--sl-color-red-800);
  --sl-color-danger-900: var(--sl-color-red-900);
  --sl-color-danger-950: var(--sl-color-red-950);
  --sl-color-neutral-50: var(--sl-color-gray-50);
  --sl-color-neutral-100: var(--sl-color-gray-100);
  --sl-color-neutral-200: var(--sl-color-gray-200);
  --sl-color-neutral-300: var(--sl-color-gray-300);
  --sl-color-neutral-400: var(--sl-color-gray-400);
  --sl-color-neutral-500: var(--sl-color-gray-500);
  --sl-color-neutral-600: var(--sl-color-gray-600);
  --sl-color-neutral-700: var(--sl-color-gray-700);
  --sl-color-neutral-800: var(--sl-color-gray-800);
  --sl-color-neutral-900: var(--sl-color-gray-900);
  --sl-color-neutral-950: var(--sl-color-gray-950);
  --sl-color-neutral-0: #fff;
  --sl-color-neutral-1000: #000;
  --sl-border-radius-small: .1875rem;
  --sl-border-radius-medium: .25rem;
  --sl-border-radius-large: .5rem;
  --sl-border-radius-x-large: 1rem;
  --sl-border-radius-circle: 50%;
  --sl-border-radius-pill: 9999px;
  --sl-shadow-x-small: 0 1px 2px #71717a0f;
  --sl-shadow-small: 0 1px 2px #71717a1f;
  --sl-shadow-medium: 0 2px 4px #71717a1f;
  --sl-shadow-large: 0 2px 8px #71717a1f;
  --sl-shadow-x-large: 0 4px 16px #71717a1f;
  --sl-spacing-3x-small: .125rem;
  --sl-spacing-2x-small: .25rem;
  --sl-spacing-x-small: .5rem;
  --sl-spacing-small: .75rem;
  --sl-spacing-medium: 1rem;
  --sl-spacing-large: 1.25rem;
  --sl-spacing-x-large: 1.75rem;
  --sl-spacing-2x-large: 2.25rem;
  --sl-spacing-3x-large: 3rem;
  --sl-spacing-4x-large: 4.5rem;
  --sl-transition-x-slow: 1s;
  --sl-transition-slow: .5s;
  --sl-transition-medium: .25s;
  --sl-transition-fast: .15s;
  --sl-transition-x-fast: 50ms;
  --sl-font-mono: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  --sl-font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --sl-font-serif: Georgia, "Times New Roman", serif;
  --sl-font-size-2x-small: .625rem;
  --sl-font-size-x-small: .75rem;
  --sl-font-size-small: .875rem;
  --sl-font-size-medium: 1rem;
  --sl-font-size-large: 1.25rem;
  --sl-font-size-x-large: 1.5rem;
  --sl-font-size-2x-large: 2.25rem;
  --sl-font-size-3x-large: 3rem;
  --sl-font-size-4x-large: 4.5rem;
  --sl-font-weight-light: 300;
  --sl-font-weight-normal: 400;
  --sl-font-weight-semibold: 500;
  --sl-font-weight-bold: 700;
  --sl-letter-spacing-denser: -.03em;
  --sl-letter-spacing-dense: -.015em;
  --sl-letter-spacing-normal: normal;
  --sl-letter-spacing-loose: .075em;
  --sl-letter-spacing-looser: .15em;
  --sl-line-height-denser: 1;
  --sl-line-height-dense: 1.4;
  --sl-line-height-normal: 1.8;
  --sl-line-height-loose: 2.2;
  --sl-line-height-looser: 2.6;
  --sl-focus-ring-color: var(--sl-color-primary-600);
  --sl-focus-ring-style: solid;
  --sl-focus-ring-width: 3px;
  --sl-focus-ring: var(--sl-focus-ring-style) var(--sl-focus-ring-width) var(--sl-focus-ring-color);
  --sl-focus-ring-offset: 1px;
  --sl-button-font-size-small: var(--sl-font-size-x-small);
  --sl-button-font-size-medium: var(--sl-font-size-small);
  --sl-button-font-size-large: var(--sl-font-size-medium);
  --sl-input-height-small: 1.875rem;
  --sl-input-height-medium: 2.5rem;
  --sl-input-height-large: 3.125rem;
  --sl-input-background-color: var(--sl-color-neutral-0);
  --sl-input-background-color-hover: var(--sl-input-background-color);
  --sl-input-background-color-focus: var(--sl-input-background-color);
  --sl-input-background-color-disabled: var(--sl-color-neutral-100);
  --sl-input-border-color: var(--sl-color-neutral-300);
  --sl-input-border-color-hover: var(--sl-color-neutral-400);
  --sl-input-border-color-focus: var(--sl-color-primary-500);
  --sl-input-border-color-disabled: var(--sl-color-neutral-300);
  --sl-input-border-width: 1px;
  --sl-input-required-content: "*";
  --sl-input-required-content-offset: -2px;
  --sl-input-required-content-color: var(--sl-input-label-color);
  --sl-input-border-radius-small: var(--sl-border-radius-medium);
  --sl-input-border-radius-medium: var(--sl-border-radius-medium);
  --sl-input-border-radius-large: var(--sl-border-radius-medium);
  --sl-input-font-family: var(--sl-font-sans);
  --sl-input-font-weight: var(--sl-font-weight-normal);
  --sl-input-font-size-small: var(--sl-font-size-small);
  --sl-input-font-size-medium: var(--sl-font-size-medium);
  --sl-input-font-size-large: var(--sl-font-size-large);
  --sl-input-letter-spacing: var(--sl-letter-spacing-normal);
  --sl-input-color: var(--sl-color-neutral-700);
  --sl-input-color-hover: var(--sl-color-neutral-700);
  --sl-input-color-focus: var(--sl-color-neutral-700);
  --sl-input-color-disabled: var(--sl-color-neutral-900);
  --sl-input-icon-color: var(--sl-color-neutral-500);
  --sl-input-icon-color-hover: var(--sl-color-neutral-600);
  --sl-input-icon-color-focus: var(--sl-color-neutral-600);
  --sl-input-placeholder-color: var(--sl-color-neutral-500);
  --sl-input-placeholder-color-disabled: var(--sl-color-neutral-600);
  --sl-input-spacing-small: var(--sl-spacing-small);
  --sl-input-spacing-medium: var(--sl-spacing-medium);
  --sl-input-spacing-large: var(--sl-spacing-large);
  --sl-input-focus-ring-color: #0ea5e966;
  --sl-input-focus-ring-offset: 0;
  --sl-input-filled-background-color: var(--sl-color-neutral-100);
  --sl-input-filled-background-color-hover: var(--sl-color-neutral-100);
  --sl-input-filled-background-color-focus: var(--sl-color-neutral-100);
  --sl-input-filled-background-color-disabled: var(--sl-color-neutral-100);
  --sl-input-filled-color: var(--sl-color-neutral-800);
  --sl-input-filled-color-hover: var(--sl-color-neutral-800);
  --sl-input-filled-color-focus: var(--sl-color-neutral-700);
  --sl-input-filled-color-disabled: var(--sl-color-neutral-800);
  --sl-input-label-font-size-small: var(--sl-font-size-small);
  --sl-input-label-font-size-medium: var(--sl-font-size-medium);
  --sl-input-label-font-size-large: var(--sl-font-size-large);
  --sl-input-label-color: inherit;
  --sl-input-help-text-font-size-small: var(--sl-font-size-x-small);
  --sl-input-help-text-font-size-medium: var(--sl-font-size-small);
  --sl-input-help-text-font-size-large: var(--sl-font-size-medium);
  --sl-input-help-text-color: var(--sl-color-neutral-500);
  --sl-toggle-size-small: .875rem;
  --sl-toggle-size-medium: 1.125rem;
  --sl-toggle-size-large: 1.375rem;
  --sl-overlay-background-color: #71717a54;
  --sl-panel-background-color: var(--sl-color-neutral-0);
  --sl-panel-border-color: var(--sl-color-neutral-200);
  --sl-panel-border-width: 1px;
  --sl-tooltip-border-radius: var(--sl-border-radius-medium);
  --sl-tooltip-background-color: var(--sl-color-neutral-800);
  --sl-tooltip-color: var(--sl-color-neutral-0);
  --sl-tooltip-font-family: var(--sl-font-sans);
  --sl-tooltip-font-weight: var(--sl-font-weight-normal);
  --sl-tooltip-font-size: var(--sl-font-size-small);
  --sl-tooltip-line-height: var(--sl-line-height-dense);
  --sl-tooltip-padding: var(--sl-spacing-2x-small) var(--sl-spacing-x-small);
  --sl-tooltip-arrow-size: 6px;
  --sl-z-index-drawer: 700;
  --sl-z-index-dialog: 800;
  --sl-z-index-dropdown: 900;
  --sl-z-index-toast: 950;
  --sl-z-index-tooltip: 1000;
}

.sl-scroll-lock {
  padding-right: var(--sl-scroll-lock-size) !important;
  overflow: hidden !important;
}

.sl-toast-stack {
  top: 0;
  z-index: var(--sl-z-index-toast);
  width: 28rem;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  inset-inline-end: 0;
  overflow: auto;
}

.sl-toast-stack sl-alert {
  margin: var(--sl-spacing-medium);
}

.sl-toast-stack sl-alert::part(base) {
  box-shadow: var(--sl-shadow-large);
}

about {
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  display: block;
  overflow: hidden auto;
}

about .center-headline {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

about .two-column {
  grid-gap: 3rem;
  grid-template-columns: 1fr fit-content(50%);
  display: grid;
}

about .two-column > :nth-child(2) {
  justify-content: center;
  align-items: center;
  display: flex;
}

about .create-gallery {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

about .create-gallery img {
  max-width: 100%;
}

@media only screen and (width <= 600px) {
  about .two-column {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  }

  about .two-column > :nth-child(2) {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

about li {
  padding: 1rem;
}

external-link {
  --sl-color-primary-400: #d3aaf1;
}

.page {
  height: calc(100dvh - var(--top-bar-height)  - 64px);
}

external-link .button-row {
  margin-top: var(--distance-l);
}

external-link .header {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

iframe {
  width: 100%;
  height: 100%;
}

external-link a {
  color: var(--color--text-link);
  cursor: pointer;
  justify-content: center;
  display: flex;
}

external-link .info {
  background-color: var(--sl-color-primary-400);
  color: var(--sl-color-white);
  padding: var(--distance-l);
  border-radius: var(--border-radius);
  margin: auto;
  margin-bottom: var(--distance-l);
  max-width: min(75%, 700px);
  display: flex;
}

external-link .link {
  cursor: pointer;
}

external-link .link-highlight {
  color: var(--color--text-link);
  text-shadow: 2px 2px 2px #fff;
  font-weight: bolder;
}

home h3 {
  text-align: center;
  width: 100%;
  margin: .7rem;
}

home loading-spinner {
  margin: 200px auto;
  display: block;
}

.tmp {
  height: 100dvh;
}

.top-bar {
  color: #8d10ad;
  background: #8d10ad;
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
}

home .top-bar a {
  color: #8d10ad;
  transition: color 1s ease-in-out;
}

home .c {
  z-index: 10;
  width: 100%;
  height: calc(100dvh - 50px);
  position: absolute;
  top: 50px;
}

home .g {
  grid-auto-flow: dense;
  grid-row-gap: 2rem;
  grid-column-gap: 1rem;
  max-width: minmax(1024px, 100%);
  grid-template-columns: repeat(auto-fit, minmax(116px, 1fr));
  place-items: center;
  height: calc(100dvh - 90px);
  display: grid;
  overflow-y: auto;
}

home .t {
  cursor: pointer;
  width: 100px;
  height: 150px;
}

price-page {
  height: calc(100dvh - var(--top-bar-height));
  display: block;
}

stripe-pricing-table {
  height: 100%;
  display: block;
  overflow-y: scroll;
}

privacy-policy {
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  display: block;
  overflow: hidden auto;
}

takedown-request {
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  display: block;
  overflow: hidden auto;
}

tou-page {
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height)  - var(--distance) * 2);
  display: block;
  overflow: hidden auto;
}

tou-page .buttons {
  padding: var(--distance-l);
  justify-content: space-around;
  display: flex;
}

tou-page dialog {
  padding: var(--distance-l);
  width: 90%;
  height: 90%;
}

tou-page ::backdrop {
  opacity: .75;
  background-image: linear-gradient(45deg, #f0f, #639, #1e90ff, indigo);
}

.add-folder {
  width: 100%;
}

.hide-modal {
  display: none;
}

.show-modal {
  display: block;
}

.add-folder .modal-dialog {
  z-index: 1;
  background-color: #0006;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.add-folder .modal-content {
  background-color: #fffaf0;
  border: 1px solid #888;
  width: -moz-fit-content;
  width: fit-content;
  margin: 15% auto;
  padding: 20px;
  box-shadow: 10px 10px 30px 15px #4f2f4f87;
}

card-component {
  margin: var(--distance);
  padding: var(--distance);
  border-radius: var(--border-radius);
  border: var(--border);
  grid-template-rows: 1fr 2fr;
  min-height: 200px;
  max-height: 200px;
  font-size: large;
  font-weight: bold;
  display: grid;
}

card-component > .heading {
  margin: var(--distance-m);
}

card-component > .info {
  margin-right: var(--distance-l);
  margin-bottom: var(--distance-l);
  place-self: end right;
  padding: 0;
  font-size: xx-large;
  font-style: italic;
}

create-gallery add-folder {
  margin-right: var(--distance);
}



.folder-component {
  gap: var(--distance);
  padding: var(--distance);
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "icon name actions";
  align-items: center;
  display: grid;
}

folder-component .icon {
  cursor: pointer;
  grid-area: icon;
  font-size: xx-large;
}

folder-component h2 {
  cursor: pointer;
  grid-area: name;
  height: auto;
  margin: 0;
  display: inline-block;
}

folder-component span {
  cursor: pointer;
}

folder-component .actions {
  flex-direction: row;
  justify-self: end;
  display: flex;
}

.gallery-view {
  width: 100%;
}

:root {
  --thumbnail-width: min(calc(100vw / 2 - var(--padding-thumbnail-label) * 4), 200px);
  --padding-thumbnail-label: 8px;
}

.thumbnail-grid {
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(var(--thumbnail-width), 1fr));
  grid-gap: 1rem;
  max-height: 28rem;
  display: grid;
  overflow: auto;
}

.img-container {
  text-align: center;
  height: 90px;
  position: relative;
}

.img-container img {
  width: var(--thumbnail-width);
  object-fit: cover;
  height: 90px;
  box-shadow: 1px 1px 3px #9b3dd190;
}

.label {
  max-width: calc(var(--thumbnail-width)  - 2 * var(--padding-thumbnail-label));
  color: #fff;
  text-shadow: 1px 1px 2px #000, -1px -1px 2px #000;
  background-color: #9b3dd190;
  border-radius: 5px;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
}

.file-name {
  max-width: calc(var(--thumbnail-width)  - 4 * var(--padding-thumbnail-label));
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 8px;
  display: inline-block;
  overflow: hidden;
}

.info {
  padding: 4px 0 8px;
}

.button-row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.sk-folding-cube {
  width: 40px;
  height: 40px;
  margin: 20px auto;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: "";
  background-color: var(--color--spinner);
  transform-origin: 100% 100%;
  width: 100%;
  height: 100%;
  animation: 2.4s linear infinite both sk-foldCubeAngle;
  position: absolute;
  top: 0;
  left: 0;
}

.sk-folding-cube .sk-cube2 {
  transform: scale(1.1)rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  transform: scale(1.1)rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  transform: scale(1.1)rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  animation-delay: .3s;
}

.sk-folding-cube .sk-cube3:before {
  animation-delay: .6s;
}

.sk-folding-cube .sk-cube4:before {
  animation-delay: .9s;
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    opacity: 0;
    transform: perspective(140px)rotateX(-180deg);
  }

  25%, 75% {
    opacity: 1;
    transform: perspective(140px)rotateX(0);
  }

  90%, 100% {
    opacity: 0;
    transform: perspective(140px)rotateY(180deg);
  }
}

top-bar {
  height: var(--top-bar-height);
  color: var(--color--component--text-main);
  background: var(--color--component--background-main);
  z-index: 100;
  justify-items: center;
  width: 100%;
  display: flex;
  position: fixed;
}

top-bar .wide-screen-menu {
  display: inline-flex;
}

top-bar .narrow-screen-menu {
  display: none;
}

top-bar sl-dropdown.narrow-screen-menu {
  padding: 5px 5px 5px var(--distance-s);
}

top-bar .narrow-screen-menu sl-icon {
  vertical-align: text-bottom;
}

@media screen and (width <= 430px) {
  top-bar .wide-screen-menu {
    display: none;
  }

  top-bar .narrow-screen-menu {
    display: inline-flex;
  }
}

top-bar nav {
  height: var(--top-bar-height);
  align-items: center;
  display: inline-flex;
}

top-bar a {
  text-decoration: none;
}

top-bar nav > a {
  padding: var(--distance);
  color: var(--color--component--text-main-link);
  border-radius: 2rem;
  transition: color 1s ease-in-out;
}

@media (hover: hover) and (pointer: fine) {
  top-bar .hover:hover {
    background-color: var(--color--component--background-secondary);
  }
}

.load-active {
  background-color: var(--color--component--background-secondary);
}

top-bar .active {
  text-decoration: underline;
}

top-bar .user-status {
  padding-right: var(--distance);
  align-self: center;
  align-items: center;
  margin-left: auto;
}

ui-notifications {
  --ui-notification-button-distance: 5rem;
  flex-direction: row;
  display: inline-flex;
  position: relative;
}

ui-notifications sl-badge {
  position: relative;
  left: 20px;
}

ui-notifications sl-icon-button {
  color: var(--white);
}

ui-notifications .fold-out {
  color: var(--color--component--text-secondary);
  background: var(--sl-panel-background-color);
  border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
  border-radius: var(--sl-border-radius-medium);
  padding: var(--sl-spacing-x-small) 0;
  flex-direction: column;
  width: 400px;
  max-width: 90dvw;
  max-height: 70vh;
  transition: all .2s ease-in-out;
  display: flex;
  overflow-y: auto;
}

@media (width <= 500px) {
  ui-notifications .fold-out {
    margin: 2dvw auto 0 5dvw;
  }
}

.notification-item {
  margin: var(--distance-s);
  border: 1px solid #ccc;
  padding: 10px;
}

.notification-item-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.notification-item-title {
  font-weight: bold;
}

.notification-item-date {
  color: #999;
  font-size: 12px;
}

.notification-item-body {
  font-size: 14px;
  line-height: 1.5;
}

.notification-item-action {
  margin-top: var(--distance-s);
  justify-content: end;
  display: flex;
}

user-card {
  margin: var(--distance);
  padding: var(--distance);
  background-color: var(--color--component--background-main);
  background: linear-gradient(90deg, var(--color--component--background-main) 0%, var(--color--component--background-secondary) 100%);
  color: var(--color--component-main);
  border-radius: var(--border-radius);
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: 1rem;
  min-height: 200px;
  display: grid;
}

user-card > .profile-pic {
  margin: var(--distance);
  justify-content: center;
  align-items: center;
  display: flex;
}

user-card > .profile-pic > img {
  border: var(--border);
  background-color: #f0f8ff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

user-card > .info-box {
  grid-template-columns: 1fr 1fr;
  height: 100%;
  display: grid;
}

user-card > .info-box > .name {
  align-self: center;
  font-size: x-large;
}

user-card > .billing-link {
  margin: var(--distance-m);
  grid-area: 2 / 2;
  margin-left: auto;
}

user-card > .billing-link > div {
  margin-bottom: var(--distance-m);
}

user-card > .billing-link > a {
  color: var(--color--component-main);
  font-style: italic;
  text-decoration: underline;
}

.user-status {
  cursor: pointer;
  margin-left: auto;
  display: flex;
}

user-status sl-icon-button {
  color: var(--white);
}

.hide-modal {
  display: none;
}

.show-modal {
  display: block;
}

.add-link .modal-dialog {
  z-index: 20;
  background-color: #0006;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.add-link .modal-content {
  background-color: #fffaf0;
  border: 1px solid #888;
  width: -moz-fit-content;
  width: fit-content;
  margin: 15% auto;
  padding: 20px;
  box-shadow: 10px 10px 30px 15px #4f2f4f87;
}

.hide-modal {
  display: none;
}

.show-modal {
  display: block;
}

.modal-dialog {
  z-index: 20;
  background-color: #0006;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  background-color: #fffaf0;
  border: 1px solid #888;
  width: 80%;
  margin: 15% auto;
  padding: 20px;
  box-shadow: 10px 10px 30px 15px #4f2f4f87;
}

.link-component {
  grid-gap: var(--distance);
  padding: var(--distance);
  grid-template: "link status actions"
  / 1fr 3rem 3rem;
  width: 100%;
  max-width: 100dvw;
  display: grid;
}

.description {
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  grid-area: link;
  align-self: center;
  display: flex;
  overflow: hidden;
}

.stats {
  text-align: center;
  grid-area: status;
}

.gu-mirror {
  opacity: .8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity= 80);
  z-index: 9999 !important;
  margin: 0 !important;
  position: fixed !important;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: .2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity= 20);
}

link-page .folder-content {
  height: auto;
  max-height: 0;
  transition: max-height .5s;
  overflow: hidden;
}

link-page .expanded {
  max-height: 31000px;
}

link-page .header {
  padding: var(--distance-s);
  justify-content: right;
  display: flex;
}

link-page .scroll-container {
  scroll-snap-type: y proximity;
  height: calc(100dvh - 56px - var(--top-bar-height));
  overflow: clip auto;
}

link-details {
  padding: var(--distance-s);
  display: block;
}

link-details .header-row {
  align-items: center;
  gap: var(--distance);
  display: flex;
}

link-details .header-row h2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

link-details p {
  align-items: center;
  display: flex;
}

link-details .link-row a {
  padding-right: var(--distance-s);
  padding-left: var(--distance-s);
}

link-details .row {
  justify-content: space-between;
  align-items: center;
  gap: var(--distance);
  display: flex;
}

link-details sl-icon {
  padding-right: var(--distance-s);
}

@media screen and (width <= 768px) {
  link-details .header-row {
    flex-direction: column;
  }

  link-details .header-row h2 {
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
  }
}

profile-page > .grid {
  grid-template-columns: var(--card-columns);
  grid-template-columns: var(--card-columns);
  grid-template-rows: max-content;
  display: grid;
}

profile-page .scroll-container {
  height: calc(100dvh - var(--top-bar-height)  - 270px);
  overflow-y: auto;
}

feedback-page {
  height: calc(100dvh - var(--top-bar-height)  - 2 * var(--distance-l));
  padding: var(--distance-l);
  display: block;
  overflow-y: auto;
}

feedback-page sl-input, feedback-page sl-textarea, feedback-page sl-button {
  margin-bottom: var(--distance-l);
}

#content.gallery {
  grid-template: "info" max-content
                 "gallery" 1fr
                 / 100%;
  gap: 0;
  display: grid;
  overflow-y: hidden;
}

#content.gallery .clickable {
  cursor: pointer;
}

#content.gallery .header {
  padding: var(--distance-s);
  border-bottom: 1px solid var(--color--background-secondary);
  flex-direction: column;
  padding-bottom: 0;
  display: flex;
}

#content.gallery .header .title-row {
  align-items: baseline;
  gap: var(--distance-s);
  flex-direction: row;
  display: flex;
}

#content.gallery .header .title-row h3 {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: var(--distance);
  grid-area: header;
  max-width: 100%;
  overflow: hidden;
}

#content.gallery .header .title-row .right {
  margin-left: auto;
}

#content.gallery .header .info-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#content.gallery .header .edit-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#content.gallery .header .edit-row .icon {
  color: #d3d3d3;
  font-size: smaller;
}

#content.gallery .header .edit-row .icon.enabled {
  color: var(--color--text-heay);
  cursor: pointer;
}

#content.gallery .header .edit-row.expanded {
  border-top: 1px solid var(--color--background-secondary);
  padding: var(--distance-s);
  max-height: 40px;
}

.gallery .thumb-grid {
  grid-area: gallery;
  grid-auto-flow: dense;
  padding-top: var(--distance);
  padding-bottom: var(--distance);
  grid-template-columns: repeat(auto-fit, minmax(164px, 1fr));
  place-items: center;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  margin: auto;
  display: grid;
  overflow-y: scroll;
}

.thumbnail-container {
  position: relative;
}

.thumbnail-container .filename {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  width: 90%;
  margin: 0;
  padding: 3px;
  display: block;
  position: absolute;
  bottom: -10px;
  overflow: hidden;
}

.thumb {
  cursor: pointer;
  margin: var(--distance-m);
  padding: var(--distance-m);
  min-width: 100px;
  min-height: 150px;
  box-shadow: 0 8px 16px #1f26875e;
}

@media screen and (width <= 600px) {
  .gallery .thumb-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .thumb {
    box-shadow: unset;
    gap: 8px;
    margin: 0;
    padding: 0;
  }
}

.thumb.selected {
  padding: calc(var(--distance-m)  - 1px);
  border: 3px solid var(--color--component--background-main);
}

.checkbox {
  top: inherit;
  left: inherit;
  width: 20px;
  height: 20px;
  padding-top: 13px;
  padding-left: 19px;
  display: inline-block;
  position: absolute;
}

.image-container {
  z-index: 100;
  place-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.full-page-bg {
  opacity: .9;
  z-index: 25;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.hidden {
  display: none;
}

.image {
  z-index: 30;
  height: inherit;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in;
  display: flex;
  position: absolute;
}

.image img {
  flex-shrink: 0;
  max-width: 100%;
  max-height: 100%;
}

.image-left {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.image-center {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.image-right {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.move-left {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.move-center {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.move-right {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.index-counter {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
}

.index-counter span {
  color: #fff;
  z-index: 30;
  -webkit-backdrop-filter: blur(8.5px);
  background: #c426eb33;
  border: 1px solid #ffffff2e;
  border-radius: 10px;
  margin: 4px 8px;
  padding: 4px 8px;
  box-shadow: 0 8px 32px #1f26875e;
}

galleries-page .header {
  padding: var(--distance-s);
  justify-content: right;
  display: flex;
}

galleries-page .scroll-container {
  height: calc(100dvh - 55px - var(--top-bar-height));
  overflow: clip auto;
}

galleries-page .empty-message {
  padding-left: 4.4rem;
  font-style: italic;
}

gallery-component .gallery-component {
  color: var(--color--component--text-main);
  background: var(--color--component--background-main);
  padding: var(--distance-m);
  cursor: pointer;
  margin: var(--distance);
  border: 1px solid #ffffff2e;
  border-radius: 10px;
}

gallery-component a {
  text-decoration: none;
}

gallery-component h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 0;
  margin-bottom: var(--distance);
  padding-right: calc(2 * var(--distance));
  overflow: hidden;
}

gallery-component .title-row {
  flex-direction: row;
  display: flex;
}

gallery-component .thumb-row {
  grid-auto-flow: column;
  justify-content: space-between;
  display: grid;
}

gallery-component .thumb-row .component-thumb {
  min-width: 100px;
  max-width: 100px;
  min-height: 150px;
  max-height: 150px;
}

@media only screen and (width <= 500px) {
  gallery-component .thumb-row .component-thumb {
    min-width: 70px;
    max-width: 70px;
    min-height: 105px;
    max-height: 105px;
  }
}

gallery-filter {
  padding: 0 var(--distance-m);
  flex-direction: row;
  align-items: center;
  gap: 10px;
  display: flex;
}

gallery-filter .dim {
  color: gray;
  cursor: pointer;
}

gallery-filter .default {
  color: var(--color--component--background-main);
  cursor: pointer;
}

gallery-filter .on {
  color: var(--color--component--background-secondary);
  cursor: pointer;
}

admin-page {
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  display: block;
  overflow: hidden auto;
}

admin-statistics {
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  grid-template-columns: var(--card-columns);
  row-gap: var(--distance);
  column-gap: var(--distance);
  display: grid;
  overflow: hidden auto;
}

week-graph {
  padding: var(--distance);
  border: var(--border);
}

week-graph > h4 {
  text-align: center;
}

month-graph {
  padding: var(--distance);
  border: var(--border);
}

month-graph > h4 {
  text-align: center;
}

admin-frontpage {
  padding: var(--distance-l);
  height: calc(100dvh - var(--top-bar-height)  - 2 * var(--distance-l));
  display: block;
  overflow: hidden auto;
}

admin-frontpage > .section {
  border: var(--border);
  border-radius: var(--border-radius);
  padding: var(--distance);
  margin-bottom: var(--distance-l);
}

admin-frontpage sl-checkbox, admin-frontpage sl-input {
  margin-bottom: var(--distance);
}

admin-frontpage > .grid {
  row-gap: var(--distance);
  column-gap: var(--distance);
  grid-template-columns: repeat(6, 1fr);
  display: grid;
}

admin-frontpage .grid-cell {
  flex-direction: column;
  display: flex;
}

gallery-details {
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  display: block;
  overflow: hidden auto;
}

admin-users {
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  display: block;
  overflow: hidden auto;
}

admin-users .no-wrap {
  white-space: nowrap;
}

admin-users .short {
  text-overflow: ellipsis;
  max-width: 20px;
  overflow: hidden;
}

admin-users .clickable {
  cursor: pointer;
  text-decoration: underline;
}

admin-users thead th:nth-child(9) {
  width: 5.5%;
}

user-profile {
  padding: var(--distance-l);
  height: calc(100dvh - var(--top-bar-height));
  display: block;
  overflow-y: auto;
}

user-profile .label {
  color: #f0f8ff;
  width: 25rem;
  max-width: 25rem;
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}

admin-feedback {
  padding: var(--distance-l);
  height: calc(100dvh - var(--top-bar-height));
  display: block;
  overflow: hidden auto;
}

admin-feedback textarea {
  height: auto;
}

admin-feedback .clickable {
  cursor: pointer;
  text-decoration: underline;
}

admin-feedback thead th:first-child {
  width: 20%;
}

admin-feedback thead th:nth-child(2) {
  width: 25%;
}

admin-feedback thead th:nth-child(4), admin-feedback thead th:nth-child(5) {
  width: 6%;
}

feedback-details {
  padding: var(--distance-l);
  display: block;
}

feedback-details .label {
  color: #f0f8ff;
  width: 15rem;
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}

feedback-details textarea {
  margin-top: var(--distance-s);
  width: 100%;
  height: auto;
}

feedback-details td.elipsis {
  text-overflow: ellipsis;
  max-width: 90px;
  overflow: hidden;
}

/*# sourceMappingURL=index.95dd3e87.css.map */
