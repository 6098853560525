admin-statistics {
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  overflow-y: auto;
  overflow-x: hidden;

  display: grid;
  grid-template-columns: var(--card-columns);
  row-gap: var(--distance);
  column-gap: var(--distance);
}