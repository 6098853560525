external-link {
  --sl-color-primary-400: rgb(211 170 241);
}

.page {
  height: calc(100dvh - var(--top-bar-height) - 64px);
}

external-link .button-row {
  margin-top: var(--distance-l);
}

external-link .header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

iframe {
  width: 100%;
  height: 100%;
}

external-link a {
  display: flex;
  justify-content: center;
  color: var(--color--text-link);
  cursor: pointer;
}

external-link .info {
  display: flex;
  margin: auto;
  max-width: min(75%, 700px);
  background-color: var(--sl-color-primary-400);
  color: var(--sl-color-white);
  padding: var(--distance-l);
  border-radius: var(--border-radius);
  margin-bottom: var(--distance-l);
}

external-link .link {
  cursor: pointer;
}

external-link .link-highlight {
  font-weight: bolder;
  color: var(--color--text-link);
  text-shadow: 2px 2px 2px white;
}