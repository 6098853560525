gallery-filter {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--distance-m);
}

gallery-filter .dim {
  color: gray;
  cursor: pointer;
}

gallery-filter .default {
  color: var(--color--component--background-main);
  cursor: pointer;
}

/* purgecss start ignore */
gallery-filter .on {
  color: var(--color--component--background-secondary);
  cursor: pointer;
}

/* purgecss end ignore */