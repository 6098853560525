:root {
  --top-bar-height: 50px;

  /* spacings */
  --distance: 8px;
  --distance-s: 8px;
  --distance-m: 16px;
  --distance-l: 24px;
  --padding-icons: 2px;
  /* spacings end */

  /* the colors in use */
  /* https://www.sessions.edu/color-calculator/ */
  --color--text-main: #383838;
  --color--text-link: black;
  --color--text-heay: #383838;
  --color--background-main: white;
  --color--background-secondary: lightgray;
  --color--background-tertiary: gray;

  --color--component--text-main: #e8e8e8;
  --color--component--background-main: #8d10ad;
  --color--component--text-secondary: rgb(36, 32, 32);
  --color--component--background-secondary: #eb26b0;
  --color--component--text-main-link: #e8e8e8;
  --color--component-main: lightgray;
  --color--component--background: white;

  --color--spinner: #6126eba0;

  --color--signal--ok: limegreen;
  --color--signal--warning: yellow;
  --color--signal--working: blueviolet;
  --color--signal--error: red;

  /* colors in use end */

  --box-shadow-s: 0 8px 16px 0 rgba(31, 38, 135, 0.37);

  --border: 0.1rem solid var(--color--component--background-main);
  --border-radius: 0.4rem;

  /* shoelace overrides */

  --sl-color-primary-50: rgb(251 248 254);
  --sl-color-primary-100: rgb(243 232 251);
  --sl-color-primary-200: rgb(234 215 249);
  --sl-color-primary-300: rgb(224 196 246);
  --sl-color-primary-400: rgb(211 170 241);
  --sl-color-primary-500: rgb(193 136 236);
  --sl-color-primary-600: rgb(173 97 230);
  --sl-color-primary-700: rgb(148 68 208);
  --sl-color-primary-800: rgb(122 56 172);
  --sl-color-primary-900: rgb(87 40 123);
  --sl-color-primary-950: rgb(54 25 76);
}

/* purgecss start ignore */

/* needed because the dragula floating element becomes an li */
li {
  list-style-type: none;
}

#wrapper {
  position: absolute;
  top: var(--top-bar-height);
  height: calc(100dvh - var(--top-bar-height));
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "content";
  overflow-y: hidden;
  overflow-x: hidden;
}

#sidebarleft {
  grid-area: sidebar-left;
}

#sidebarright {
  grid-area: sidebar-right;
}

#content {
  grid-area: content;
  height: calc(100dvh - var(--top-bar-height));
}

.signal-ok {
  color: var(--color--signal--ok);
}

.signal-working {
  color: var(--color--signal--working);
}

.signal-warning {
  color: var(--color--signal--warning);
}

.signal-error {
  color: var(--color--signal--error);
}

.pulse:hover {
  animation: pulse 0.5s normal;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

.pulse-small:hover {
  animation: pulsesmall 0.35s normal;
  animation-timing-function: linear;
}

@keyframes pulsesmall {
  0% {
    transform: scale(1);
  }

  50% {
    transform: translateX(2rem) scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.zoom:hover {
  transform: scale(1.1);
  transition: all ease 500ms;
}

/* purgecss end ignore */

@media only screen and (max-width: 600px) {
  :root {
    --thumb-columns: 3;
    --card-columns: 1fr;
  }

  h2 {
    font-size: x-large;
  }

  h3 {
    font-size: large;
  }

  #wrapper {
    grid-template-columns: 1px minmax(calc(100vw - 2px), 1fr) 1px;
    grid-template-areas: "sidebar-left content sidebar-right";
  }
}

@media only screen and (min-width: 601px) {
  :root {
    --thumb-columns: 4;
    --card-columns: 1fr 1fr;
  }

  #wrapper {
    grid-template-columns: 1px minmax(calc(100vw - 2px), 1fr) 1px;
    grid-template-areas: "sidebar-left content sidebar-right";
  }
}

@media only screen and (min-width: 1024px) {
  :root {
    --thumb-columns: 7;
    --card-columns: 1fr 1fr 1fr;
  }

  h2 {
    font-size: x-large;
  }

  h3 {
    font-size: large;
  }

  #wrapper {
    grid-template-columns: 1fr minmax(1024px, 1fr) 1fr;
    grid-template-areas: "sidebar-left content sidebar-right";
  }
}

html {
  overflow: hidden;
}

body {
  margin: 0px;
  background-color: var(--color--background-main);
  font-family: "Inter", sans-serif;
}

a,
.link {
  color: var(--color--text-link);
  cursor: pointer;
}

.padding {
  padding: var(--distance);
}

.padding-s {
  padding: var(--distance-s);
}

.padding-m {
  padding: var(--distance-m);
}

.padding-l {
  padding: var(--distance-l);
}

.pull-right {
  float: right;
}

/* used to censor content */
.blur {
  filter: blur(2rem);
}

dialog {
  width: 80vw;
  max-width: 800px;
  padding: var(--distance-l);

  .button-row {
    padding-top: calc(2 * var(--distance-l));
    display: flex;
    justify-content: space-between;
  }
}

/* tables */
table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 3px solid purple;
}

th,
td {
  padding: 1%;
  border: 1px solid purple;
  text-align: left;
}

td.elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90px;
  white-space: nowrap;
}



/* Shoe lace */
sl-icon,
sl-icon-button {
  font-size: 1.4rem;
  transition: all 0.2s ease-in-out;
}