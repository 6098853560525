tou-page {
  display: block;
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height) - var(--distance) * 2);
  overflow: hidden auto;

  .buttons {
    padding: var(--distance-l);
    display: flex;
    justify-content: space-around;
  }

  dialog {
    width: 90%;
    height: 90%;
    padding: var(--distance-l);
  }

  ::backdrop {
    background-image: linear-gradient(45deg,
        magenta,
        rebeccapurple,
        dodgerblue,
        indigo);
    opacity: 0.75;
  }
}