home h3 {
  margin: 0.7rem;
  /* padding: 0.7rem; */
  width: 100%;
  text-align: center;
}

home loading-spinner {
  display: block;
  margin: 200px auto;
}

.tmp {
  height: 100dvh;
}

.top-bar {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0px;
  color: #8d10ad;
  background: #8d10ad;
}

home .top-bar a {
  color: #8d10ad;
  transition: color ease-in-out 1s;
}

home .c {
  width: 100%;
  height: calc(100dvh - 50px);
  position: absolute;
  top: 50px;
  z-index: 10;
}

home .g {
  height: calc(100dvh - 50px - 40px);
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(116px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 1rem;
  justify-items: center;
  align-items: center;
  max-width: minmax(1024px, 100%);
  overflow-y: auto;
}

home .t {
  width: 100px;
  height: 150px;
  cursor: pointer;
}