user-profile {
  display: block;
  padding: var(--distance-l);
  height: calc(100dvh - var(--top-bar-height));
  overflow-y: auto;
}

user-profile .label {
  position: relative;
  display: inline-block;
  width: 25rem;
  max-width: 25rem;
  color: aliceblue;
  top: 0;
  left: 0;
  transform: none;
}