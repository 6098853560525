.link-component {
  width: 100%;
  max-width: 100dvw;

  display: grid;
  grid-template-columns: 1fr 3rem 3rem;
  grid-template-areas: "link status actions";
  grid-template-rows: auto;
  grid-gap: var(--distance);
  padding: var(--distance);
}

.description {
  grid-area: link;

  display: flex;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.stats {
  text-align: center;
  grid-area: status;
}