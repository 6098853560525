admin-users {
  display: block;
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  overflow-y: auto;
  overflow-x: hidden;
}

admin-users .no-wrap {
  white-space: nowrap;
}

admin-users .short {
  MAX-WIDTH: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

admin-users .clickable {
  cursor: pointer;
  text-decoration: underline;
}

admin-users thead th:nth-child(9) {
  width: 5.5%;
}