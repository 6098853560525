feedback-page {
  height: calc(100dvh - var(--top-bar-height) - 2 * var(--distance-l));
  display: block;
  padding: var(--distance-l);
  overflow-y: auto;
}

feedback-page sl-input,
feedback-page sl-textarea,
feedback-page sl-button {
  margin-bottom: var(--distance-l);
}