galleries-page .header {
  display: flex;
  justify-content: right;
  padding: var(--distance-s);
}

galleries-page .scroll-container {
  overflow-y: auto;
  overflow-x: clip;
  /* window height - top bar height - header height */
  height: calc(100dvh - 55px - var(--top-bar-height));
}

galleries-page .empty-message {
  padding-left: 4.4rem;
  font-style: italic;
}