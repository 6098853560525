about {
  display: block;
  padding: var(--distance);
  height: calc(100dvh - var(--top-bar-height));
  overflow-y: auto;
  overflow-x: hidden;
}

about .center-headline {
  margin-top: 5rem;
  margin-bottom: 5rem;
  text-align: center;
}

about .two-column {
  display: grid;
  grid-template-columns: 1fr fit-content(50%);
  grid-gap: 3rem;
}

about .two-column> :nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}

about .create-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

about .create-gallery img {
  max-width: 100%;
}

@media only screen and (max-width: 600px) {
  about .two-column {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  about .two-column> :nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

about li {
  padding: 1rem;
}