.folder-component {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "icon name actions";
  gap: var(--distance);
  align-items: center;
  padding: var(--distance);
}

folder-component .icon {
  grid-area: icon;
  font-size: xx-large;
  cursor: pointer;
}

folder-component h2 {
  display: inline-block;
  margin: 0px;
  height: auto;
  cursor: pointer;

  grid-area: name;
}

folder-component span {
  cursor: pointer;
}

folder-component .actions {
  display: flex;
  flex-direction: row;
  justify-self: end;
}