user-card {
  margin: var(--distance);
  padding: var(--distance);
  background-color: var(--color--component--background-main);
  background: linear-gradient(90deg, var(--color--component--background-main) 0%, var(--color--component--background-secondary) 100%);
  color: var(--color--component-main);
  border-radius: var(--border-radius);

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
  align-items: center;
  min-height: 200px;
}

user-card>.profile-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--distance);
}

user-card>.profile-pic>img {
  background-color: aliceblue;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: var(--border);
}

user-card>.info-box {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
}

user-card>.info-box>.name {
  font-size: x-large;
  align-self: center;
}

user-card>.billing-link {
  grid-area: 2/2;
  margin: var(--distance-m);
  margin-left: auto;
}

user-card>.billing-link>div {
  margin-bottom: var(--distance-m);
}

user-card>.billing-link>a {
  color: var(--color--component-main);
  text-decoration: underline;
  font-style: italic;
}