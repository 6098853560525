/* purgecss start ignore */

.hide-modal {
  display: none;
}

.show-modal {
  display: block;
}

/* purgecss end ignore */

.modal-dialog {
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  /* height: 100%; Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: floralwhite;
  box-shadow: 10px 10px 30px 15px rgba(79, 47, 79, 0.53);
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
