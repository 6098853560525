profile-page>.grid {
  display: grid;
  grid-template-columns: var(--card-columns);
}
profile-page>.grid {
  display: grid;
  grid-template-columns: var(--card-columns);
  grid-template-rows: max-content;
}

profile-page .scroll-container {
  /* height = full windows - top bar - profile card */
  height: calc(100dvh - var(--top-bar-height) - 270px);
  overflow-y: auto;
}