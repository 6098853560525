ui-notifications {
  --ui-notification-button-distance: 5rem;
  display: inline-flex;
  flex-direction: row;
  position: relative;
}

ui-notifications sl-badge {
  position: relative;
  left: 20px;
}

ui-notifications sl-icon-button {
  color: var(--white);
}

ui-notifications .fold-out {
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;

  max-height: 70vh;
  width: 400px;
  max-width: 90dvw;
  overflow-y: auto;

  color: var(--color--component--text-secondary);
  background: var(--sl-panel-background-color);
  border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
  border-radius: var(--sl-border-radius-medium);
  padding: var(--sl-spacing-x-small) 0;
}

@media (max-width: 500px) {
  ui-notifications .fold-out {
    margin: 2dvw auto 0 5dvw;
  }
}

.notification-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: var(--distance-s)
}

.notification-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.notification-item-title {
  font-weight: bold;
}

.notification-item-date {
  font-size: 12px;
  color: #999;
}

.notification-item-body {
  font-size: 14px;
  line-height: 1.5;
}

.notification-item-action {
  display: flex;
  justify-content: end;
  margin-top: var(--distance-s);
}