feedback-details {
  display: block;
  padding: var(--distance-l);
}

feedback-details .label {
  position: relative;
  display: inline-block;
  width: 15rem;
  color: aliceblue;
  top: 0;
  left: 0;
  transform: none;
}

feedback-details textarea {
  width: 100%;
  height: auto;
  margin-top: var(--distance-s);
}

feedback-details td.elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90px;
}