card-component {
  min-height: 200px;
  max-height: 200px;
  margin: var(--distance);
  padding: var(--distance);
  border-radius: var(--border-radius);
  border: var(--border);
  display: grid;
  grid-template-rows: 1fr 2fr;
  font-size: large;
  font-weight: bold;
  /* grid-template-columns: 1fr 2fr; */
}

card-component>.heading {
  margin: var(--distance-m);
}

card-component>.info {
  align-self: end;
  justify-self: right;
  font-style: italic;
  font-size: xx-large;
  margin-right: var(--distance-l);
  margin-bottom: var(--distance-l);
  padding: 0px;
}