admin-feedback {
  display: block;
  padding: var(--distance-l);
  height: calc(100dvh - var(--top-bar-height));
  overflow-y: auto;
  overflow-x: hidden;
}

admin-feedback textarea {
  height: auto;
}

admin-feedback .clickable {
  cursor: pointer;
  text-decoration: underline;
}

admin-feedback thead th:nth-child(1) {
  width: 20%;
}

admin-feedback thead th:nth-child(2) {
  width: 25%;
}

admin-feedback thead th:nth-child(4) {
  width: 6%;
}

admin-feedback thead th:nth-child(5) {
  width: 6%;
}