admin-frontpage {
  display: block;
  padding: var(--distance-l);
  height: calc(100dvh - var(--top-bar-height) - 2 * var(--distance-l));
  overflow-y: auto;
  overflow-x: hidden;
}

admin-frontpage>.section {
  border: var(--border);
  border-radius: var(--border-radius);
  padding: var(--distance);
  margin-bottom: var(--distance-l);
}

admin-frontpage sl-checkbox,
admin-frontpage sl-input {
  margin-bottom: var(--distance);
}

admin-frontpage>.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: var(--distance);
  column-gap: var(--distance);
}

admin-frontpage .grid-cell {
  display: flex;
  flex-direction: column;
}