/* dragula css copied  */
/* purgecss start ignore */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

link-page .folder-content {
  overflow: hidden;
  transition: max-height 0.5s ease;
  height: auto;
  max-height: 0px;
}

link-page .expanded {
  max-height: 31000px;
}

/* purgecss end ignore */


link-page .header {
  display: flex;
  justify-content: right;
  padding: var(--distance-s);
}

link-page .scroll-container {
  scroll-snap-type: y proximity;

  overflow-y: auto;
  overflow-x: clip;
  /* 100vh - header height - menu height */
  height: calc(100dvh - 56px - var(--top-bar-height));
}