gallery-component .gallery-component {
  color: var(--color--component--text-main);
  background: var(--color--component--background-main);

  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: var(--distance-m);
  cursor: pointer;
  margin: var(--distance);
}

gallery-component a {
  text-decoration: none;
}

gallery-component h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
  margin-bottom: var(--distance);
  padding-right: calc(2 * var(--distance));
}

gallery-component .title-row {
  display: flex;
  flex-direction: row;
}

gallery-component .thumb-row {
  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;
}

gallery-component .thumb-row .component-thumb {
  max-width: 100px;
  min-width: 100px;
  max-height: 150px;
  min-height: 150px;
}

@media only screen and (max-width: 500px) {
  gallery-component .thumb-row .component-thumb {
    max-width: 70px;
    min-width: 70px;
    max-height: 105px;
    min-height: 105px;
  }
}