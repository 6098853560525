link-details {
  display: block;
  padding: var(--distance-s);

  .header-row {
    display: flex;
    align-items: center;
    gap: var(--distance);

    h2 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  p {
    display: flex;
    align-items: center;
  }

  .link-row {
    a {
      padding-right: var(--distance-s);
      padding-left: var(--distance-s);
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--distance);
  }

  sl-icon {
    padding-right: var(--distance-s);
  }

}

@media screen and (max-width: 768px) {
  link-details {
    .header-row {
      flex-direction: column;

      h2 {
        text-overflow: unset;
        overflow: unset;
        white-space: unset;
      }
    }

  }
}