#content.gallery {
  /* TODO: remove when perfect */
  overflow-y: hidden;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: max-content 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "info"
    "gallery";

  .clickable {
    cursor: pointer;
  }

  .header {
    display: flex;
    flex-direction: column;
    padding: var(--distance-s);
    padding-bottom: 0px;

    border-bottom: 1px solid var(--color--background-secondary);

    .title-row {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: var(--distance-s);

      h3 {
        text-align: left;
        grid-area: header;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: var(--distance);
        max-width: 100%;
      }

      .right {
        margin-left: auto;
      }
    }

    .info-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .edit-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .icon {
        color: lightgray;
        font-size: smaller;
      }

      .icon.enabled {
        color: var(--color--text-heay);
        cursor: pointer;
      }
    }

    .edit-row.expanded {
      border-top: 1px solid var(--color--background-secondary);
      max-height: 40px;
      padding: var(--distance-s);
    }


  }
}

.gallery .thumb-grid {
  grid-area: gallery;
  height: 100%;
  width: 100%;

  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(164px, 1fr));
  justify-items: center;
  align-items: center;

  max-width: 1024px;
  margin: auto;

  overflow-y: scroll;
  padding-top: var(--distance);
  padding-bottom: var(--distance);
}

.thumbnail-container {
  position: relative;
}

.thumbnail-container .filename {
  position: absolute;
  bottom: -10px;
  display: block;
  width: 90%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
  padding: 0px;
  background-color: white;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid black;
}

.thumb {
  cursor: pointer;
  min-width: 100px;
  min-height: 150px;
  box-shadow: 0 8px 16px 0 rgba(31, 38, 135, 0.37);

  margin: var(--distance-m);
  padding: var(--distance-m);
}

@media screen and (max-width: 600px) {
  .gallery .thumb-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .thumb {
    box-shadow: unset;
    margin: 0px;
    padding: 0px;
    gap: 8px;
  }
}


.thumb.selected {
  padding: calc(var(--distance-m) - 1px);
  border: 3px solid var(--color--component--background-main);
}

.checkbox {
  position: absolute;
  display: inline-block;
  top: inherit;
  left: inherit;
  height: 20px;
  width: 20px;
  padding-left: 19px;
  padding-top: 13px;
}

/* image viewer */
.image-container {
  display: none;
  justify-items: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;

  position: fixed;
  overflow: hidden;
}

.full-page-bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.9;
  z-index: 25;
}

.hidden {
  display: none;
}

.image {
  position: absolute;
  transition: all 0.2s ease-in;
  z-index: 30;

  display: flex;
  justify-content: center;
  align-items: center;

  height: inherit;
}

.image img {
  max-width: 100%;
  max-height: 100%;
  flex-shrink: 0;
}

.image-left {
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
}

.image-center {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.image-right {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}

/* purgecss start ignore */
.move-left {
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
}

.move-center {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.move-right {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}

/* purgecss end ignore */

.index-counter {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.index-counter span {
  color: white;
  z-index: 30;

  background: rgba(196, 38, 235, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  margin: 4px 8px;
  padding: 4px 8px;
}